import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;

    img {
         max-height: 440px;
    }

    ${({doubleImage}) => `${doubleImage && `
        @media ${breakpoint.sm} {
            grid-template-rows: 440px auto;
        }
    `}
    `}


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }

   /*  img {
        max-height: 440px;
        width: auto;
    } */
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 2rem;
`

const SectionTitle = styled.h3`
    padding-top: 1em;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Rota Vicentina v prostisměru</Title>
            <Info>
                <Author>Simona</Author>
                <Date>30. 4. 2023</Date>
            </Info>
            <p>V září 2022 jsem se poprvé sama vydala na vícedenní pěší <a href="https://rotavicentina.com/en/walking/fishermens-trail/">trek</a>. Přípravy začaly zhruba o měsíc dřív nákupem letenek z Vídně do Fara, malého městečka na jihu Portugalska, kde moje dvou týdenní dobrodružství začalo.</p>
            <SectionTitle>Trek do protisměru</SectionTitle>
            <p>Nápad vydat se na vícedenní trek mi doporučily kolegyně v práci, jsou to zkušené trekařky a mě bavit chodí, tak jsem si řekla: &quot;proč ne&quot;? Věděla jsem jen, že se jedná opobřežní trek v Portugalsku a Zuzka jej šla směrem od Lisabonu k jihu. Mně přišlo logičtější jít z jihu na sever, a tak jsem si podle tohoto pocitu koupila letenky. Přílet do Fara, odlet z Lisabonu.</p>
            <p>Později při podrobnějším studování toho, kam se to vlastně vydávám, jsem ale zjistila, že trek je plánován spíše od severu na jih. Vadilo to? To se dozvíte na konci tohoto článku.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/rota/rota1.jpeg" alt=""></StaticImage>
                    <StyledFigcaption>Zeleno-modré pruhy vás budou provázet na celé trase.</StyledFigcaption>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/rota/rota2.jpeg" alt=""></StaticImage>
                    <StyledFigcaption>Pobřeží v Lagosu, Praia do Ana</StyledFigcaption>
                </StyledFigure>
            </DoubleImage>
            <StyledFigure>
                    <StaticImage src="../assets/images/blog/rota/rota3.jpeg" alt=""></StaticImage>
                    <StyledFigcaption>Západy slunce jsou v Portugalsku tak úchvantný, že jsem si nakonec s sebou odvezla jeden trvalý suvenýr. Uhodnete, co to bylo?</StyledFigcaption>
            </StyledFigure>
            <p>Proč má trek 226,5 km a já šla jen 180? Oficiálně je trek sestavený jako trasa s 13 zastávkami. Pokud jdete ze severu na jih pravděpodobně budete začínat v Sao Torpes nebo v Porto Covo a cílem je dojít do Sagresu, kde se nachází mys Svatého Vincenta a je pár kilometrů od nejjihozápadnějšího cípu Evropy.</p>
            <p>Následně pak můžete svoji trasu prodloužit až do Lagosu. Což byl vlastně i můj záměr, akorát v opačném směru.</p>
            <p>Moje trasa nakonec kvůli počasí začala místo v Lagosu, v Salemě, odkud jsem se vydala do hostelu ve vesnici Hortas do Tabual. Protože v rámci celého úseku Roty Vicentiny není povoleno spaní na divoko, rovnou jsem měla téměř na celou trasu rezervovaná místa v hostelech. Asi 6 nocí jsem si nechala k dispozici na přizpůsobení se na poslední chvíli, což se mi taky jeden den hezky vymstilo.</p>
            <p>Už při plánování trasy jsem si říkala, že vesnička Aljezur mě úplně nezaujala a že by mi nevadilo ji vynechat. A tak den před příchodem jsem zjistila, že už tam nejsou žádné volné postele a já si musím spojit dva úseky dohromady. Výsledkem bylo, že jsem si vyzkoušela, jaké to je, jít dva úseky v jeden den a pomyslně na tom pak &quot;získala&quot; den volna v Odeceixe na pláži. Až na to, že z centra vesnice na pláž, je to pěšky asi 4 km.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/rota/rota4.jpeg" alt=""></StaticImage>
                    <StyledFigcaption>První den treku. Čekání na to, až přestane pršet a já budu moci vyrazit.</StyledFigcaption>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/rota/rota5.webp" alt=""></StaticImage>
                    <StyledFigcaption>Výhledy, které nikdy neomrzí. Playa do Amado poblíž Carrapateiry.</StyledFigcaption>
                </StyledFigure>
            </DoubleImage>
            <p>První tři úseky jsem byla přesvědčená, že jdu celou trasu do protisměru sama. Ale na dalším úseku jsem potkala skupinku čtyř přátel důchodového věku z Irska a jednu němku, Anouk, se kterou jsme se nakonec až do konce společné trasy předcházely, scházely a rozcházely.</p>
            <p>Co zpětně vidím jako hodně pozitivní je, že jsme si trasu do protisměru opravdu mohli všichni užít v relativním soukromí a samotě, protože jak jsem se v průběhu cesty dozvěděla, nejpopulárnější jsou pro turisty zhruba čtyři první úseky (S.Torpes - Porto Covo - Vila Nova de Milfontes - Almogarve - Zambujejira do Mar) ze severu na jih. Spousta jich taky jde jen těchto pár úseků a čím víc jsem se blížila k severu, tím byla trasa v protisměru plnější.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/rota/rota6.jpeg" alt=""></StaticImage>
                    <StyledFigcaption>Lekce surfu den třetí.</StyledFigcaption>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/rota/rota7.webp" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Na závěr mé cesty jsem zůstala 4 noci v městečku Vila Nova de Milfontes, které jsem si vyhlídla už předem na mapách. Městečko je vybavené vším, co na prázdninách potřebuji: mini obchůdky, markety, tržnice, restaurace, bary a několik surf škol. <a href="https://club-vagabond.com/">Jednu</a> z nich jsme s Anouk vyzkoušely a já s nimi absolvovala 3 večerní lekce při západu slunce.</p>
            <p>A tak se pro mě surf stal novou láskou, se kterou chci v budoucnu určitě budovat vztah. Na památku jsem si v Lisabonu před odletem nechala udělat vlastní západ slunce na levé zápěstí, abych se tam kdykoliv v myšlenkách mohla vrátit.</p>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
